"use client"

import Attention from "@/assets/Attention"
import { datadogRum } from "@datadog/browser-rum"
import Button from "@mui/material/Button"
import Link from "@mui/material/Link"
import Paper from "@mui/material/Paper"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import React from "react"

const supportEmail = "support@ikhokha.com"
const emailLink = `mailto:${supportEmail}`
const EmailLinkComponent = () => <Link href={emailLink}>{supportEmail}</Link>

const supportNumber = "087 222 7000"
const phoneNumberLink = `tel:${supportNumber.replaceAll(" ", "")}`
const PhoneNumberLinkComponent = () => <Link href={phoneNumberLink}>{supportNumber}</Link>

const onRetry = () => window.location.reload()

export default function OrderIdError({ error }: Readonly<{ error: Error & { digest?: string } }>) {
	React.useEffect(() => {
		datadogRum.addError(error)
	}, [error])

	return (
		<Paper
			elevation={3}
			sx={{
				width: "25rem",
				height: "28rem",
			}}
		>
			<Stack gap={3} justifyContent="center" alignItems="center" textAlign="center" paddingTop={4} paddingX={1.5}>
				<Attention width={202} height={174} />
				<Stack gap={1}>
					<Typography variant="h6">Server Error</Typography>
					<Typography
						sx={{
							color: "text.secondary",
						}}
						variant="body1"
					>
						We couldn't reach the iKhokha server. Please try again or contact ikhokha Support Team on <PhoneNumberLinkComponent />{" "}
						or email us at <EmailLinkComponent />
					</Typography>
				</Stack>
				<Button fullWidth onClick={onRetry}>
					Retry
				</Button>
			</Stack>
		</Paper>
	)
}
